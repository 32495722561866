export const COMPANY_NAME = "OostWest";
export const COMPANY_ADDRESS_LINE = "Heliumweg 15";
export const COMPANY_CITY = "Amersfoort";
export const COMPANY_ZIP_CODE = "3812 RD";
export const COMPANY_EMAIL = "aanvragen@oostwest.online";
export const COMPANY_PHONE = "0612345678";

export const SOCIAL_MEDIA_LINKEDIN_URL =
  "https://www.linkedin.com/company/oostwestcity";
export const SOCIAL_MEDIA_INSTAGRAM_URL =
  "https://www.instagram.com/oostwest_/";

export const SOCIAL_MEDIA_TIKTOK_URL =
  "https://www.tiktok.com/@oostwest__?_t=8mkqLU2yRjF&_r=1";
