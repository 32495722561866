import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { StyledCard } from "../../styles/sharedStyles";

const ImageTextCards = (data) => {
  return (
    <Wrapper className={"row"}>
      <SliderWrapper
        slidesPerView={1}
        spaceBetween={30}
        pagination={{ clickable: true, dynamicBullets: true }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        {data.images.nl.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <CommunityCard>
                <img src={item.image} alt={item.image_alt} />
                <CardContent>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </CardContent>
              </CommunityCard>
            </SwiperSlide>
          );
        })}
      </SliderWrapper>
    </Wrapper>
  );
};

const SliderWrapper = styled(Swiper)`
  padding: 1rem 0.5rem 1.5rem;

  .swiper-pagination {
    margin-top: 2rem;
  }

  .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 0.8rem;
    height: 0.8rem;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.secondary};
  }
`;

const CardContent = styled.div`
  padding: 2rem 1.2rem;
  text-align: center;
`;

const CommunityCard = styled(StyledCard)`
  img {
    width: 100%;
    -webkit-mask-image: url("/assets/images/masks/mask-2.png");
    mask-image: url("/assets/images/masks/mask-2.1.png");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  h4 {
    color: ${colors.header};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 0.3rem;
  }

  p {
    margin-bottom: 0;
    line-height: 24px;
  }
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;

export default ImageTextCards;
