const API_URL = process.env.REACT_APP_API_URL + "/api";
const PAYMENT_URL = API_URL + "/stripe";
const MAIL_URL = API_URL + "/mail";
const APP_BASE = "/app";
module.exports = {
  home: "/",
  about: "/over",
  links: "/links",
  contact: "/contact",
  privacy: "/privacy",
  terms_conditions: "/algemene-voorwaarden",
  register: "/registreren",
  login: "/inloggen",
  products: "/producten",
  flexWorkPace: "/producten/flexwerken",
  fixedUnit: "/producten/vaste-unit",
  multiFunctionalSpace: "/producten/multifunctionele-ruimte",
  productDetail: "/producten/:productId",
  publicEvents: "/evenementen",
  community: "/community",
  dashboardHome: APP_BASE,
  events: APP_BASE + "/evenementen",
  agenda: APP_BASE + "/agenda",
  chat: APP_BASE + "/chat",
  profile: APP_BASE + "/profiel",
  settings: APP_BASE + "/instellingen",
  usersOverview: APP_BASE + "/gebruikers",
  adminOverview: APP_BASE + "/admin",
  userProfile: APP_BASE + "/gebruiker/:userId",
  userProfileRaw: APP_BASE + "/gebruiker/",
  registerSubscription: "/abonnement/registreren",
  checkoutSubscription: "/abonnement/betalen",
  confirmSubscription: "/abonnement/geslaagd",
  failedSubscription: "/abonnement/mislukt",
  podcastBackstage: "/backstage",
  addSubscriptionContactDetails: "/abonnement/gegevens-invullen",
  createSubscription: PAYMENT_URL + "/subscription",
  acceptHireRequest: MAIL_URL + "/hire_request/accepted",
  sendPaymentLink: PAYMENT_URL + "/request/accept",
  sendHireSpaceConfirmation: MAIL_URL + "/hire_space/received",
  sendContactMail: MAIL_URL + "/contact",
  requestPinCodeLink: MAIL_URL + "/user/login-code",
  pinCodeVerificationLink: API_URL + "/authentication/login",
  createNewMember: MAIL_URL + "/user/create",
};
