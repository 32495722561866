import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../utils/theme";
import EnterEmailBanner from "../components/Banners/EnterEmailBanner";
import CtaBanner from "../components/Banners/CtaBanner";
import { device } from "../utils/breakpoints";
import StyleElement from "../components/StyleElement";
import AboutSection from "../components/Home/AboutSection";
import HomeLayout from "../layouts/HomeLayout";
import CommunityBanner from "../components/Home/CommunityBanner";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import LinkButton from "../components/LinkButton";
import { about, contact, flexWorkPace, multiFunctionalSpace } from "../urls";
import ImageAndTextBanner from "../components/Banners/ImageAndTextBanner";
import AboutData from "../data/grid-mosaic.json";
import communityImages from "../data/community-cards.json";

const LandingPage = () => {
  const { t } = useTranslation();
  const headerRef = useRef(null);

  useEffect(() => {
    let i = 0;
    setInterval(function () {
      const element = headerRef.current;
      if (!!element) {
        switch (i) {
          case 0:
            element.style.backgroundImage =
              'url("assets/images/impression/1600x1146-talks-1.webp")';
            break;
          case 1:
            element.style.backgroundImage =
              'url("assets/images/impression/1600x1146-ow-by-night.webp")';
            break;
          case 2:
            element.style.backgroundImage =
              'url("assets/images/impression/ferdinand-on-workspace.webp")';
            break;
          case 3:
            element.style.backgroundImage =
              'url("assets/images/impression/bassist-oostwest.webp")';
            break;
          case 4:
            element.style.backgroundImage =
              'url("assets/images/impression/raven-on-container.webp")';
            break;
          case 5:
            element.style.backgroundImage =
              'url("assets/images/impression/audience-inside.webp")';
            break;
          case 6:
            element.style.backgroundImage =
              'url("assets/images/impression/light-installation.webp")';
            break;
          default:
            element.style.backgroundImage =
              'url("assets/images/impression/1600x1146-flex-1.webp")';
        }
        i++;
        if (i > 4) i = 0;
      }
    }, 3000);
  }, []);

  return (
    <HomeLayout>
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-06.svg"}
          alt={""}
          left
          bottom={-110}
        />
      </div>

      <HeaderWrapper>
        <div className="banner-image">
          <div className="image-container" ref={headerRef} />
        </div>
        <div className="banner-content">
          <div className="content-container">
            <HeaderTitle>{t("header_title")}</HeaderTitle>
            <HeaderSubtitle>{t("header_subtitle")}</HeaderSubtitle>
            <HeaderDescription>{t("header_description")}</HeaderDescription>
            <div className={"d-flex mt-2"}>
              <LinkButton to={about} small>
                {t("read_more_oostwest")}
              </LinkButton>
              <LinkButton to={contact} small type={"outlined"}>
                {t("get_in_touch")}
              </LinkButton>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <AboutSection
        data={AboutData}
        title={t("home_about_title")}
        description={t("home_about_description")}
        cta={t("home_about_lead")}
        btnText={t("read_more_oostwest")}
        btnLink={about}
      />
      <div className={"position-relative"}>
        <LeavesStyleElement
          src={"assets/images/illustrations/illustratie-18.svg"}
          alt={""}
        />
      </div>

      <ImageAndTextBanner
        src={"assets/images/ruimte/multi-ruimte-2.webp"}
        alt={""}
        title={t("multipurpose_space_title")}
        description={t("multipurpose_space_description")}
        displayButtons={true}
        flip
        btnLink1={multiFunctionalSpace}
        btnText1={t("see_product")}
        btnLink2={contact}
        btnText2={t("get_in_touch")}
      />

      <ImageAndTextBanner
        src={"assets/images/ruimte/flex-1.1.webp"}
        alt={""}
        title={t("flex_work_title")}
        description={t("flex_work_description")}
        btnLink1={flexWorkPace}
        btnText1={t("see_product")}
        btnLink2={contact}
        btnText2={t("get_in_touch")}
      />

      <CommunityBanner
        title={t("home_community_title")}
        description={t("home_community_description")}
        images={communityImages}
        displayButton
      />

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-09.svg"}
          alt={""}
          bottom={-30}
          left
        />
      </div>

      <EnterEmailBanner />

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-14.svg"}
          alt={""}
          bottom={-83}
          left
        />
      </div>

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-18.svg"}
          alt={""}
          bottom={-30}
          right={-18}
        />
      </div>
      <CtaBanner light />
      <HireSpaceModal />
    </HomeLayout>
  );
};

const LeavesStyleElement = styled.img`
  position: absolute;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.1;
  width: 600px;
  bottom: -20vh;
  right: -15vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -45vh;
    right: -25vh;
  }
`;

const HeaderSubtitle = styled.h4`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media ${device.lg} {
    font-size: 22px;
    line-height: 32px;
  }
`;

const HeaderDescription = styled.p`
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;

  @media ${device.lg} {
    font-size: 24px;
    line-height: 30px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${colors.header};
  @media ${device.lg} {
    font-size: 38px;
  }
`;

const HeaderWrapper = styled.div`
  height: 100vh;
  display: grid;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 20vh;
  z-index: 9;
  isolation: isolate;

  .banner-content {
    grid-column: 1;
    display: grid;
    place-content: center;

    .content-container {
      max-width: 600px;
      padding-inline: 2rem;
    }
  }

  .banner-image {
    display: flex;
    align-items: center;
    min-height: 35vh;
  }

  .image-container {
    display: grid;
    place-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: 0 20px 40px 4px rgba(0, 0, 0, 0.2);
    transition: background 1.5s ease;

    background-image: url("/assets/images/impression/1600x1146-flex-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media ${device.lg} {
    padding-top: 0;
    grid-template-columns: 1fr 1fr;

    .banner-image {
      padding-left: 4rem;
      grid-column: 2;
      grid-row: 1;
    }

    .image-container {
      height: 80%;
      border-radius: 6px 0 0 6px;
      box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
    }

    .content-container {
      margin-left: 10vw;
    }
  }
`;

export default LandingPage;
