import React from "react";
import styled from "styled-components";
import { colors, styles } from "../utils/theme";
import { useTranslation } from "react-i18next";
import { device } from "../utils/breakpoints";
import TikTokSvg from "../assets/icons/tik-tok.svg";
import { UpOutlined } from "@ant-design/icons";

const UserCard = ({ user, isExpanded, onExpand }) => {
  const { t } = useTranslation();

  return (
    <StyledUserCard>
      <CardContainer>
        <ContentWrapper isExpanded={isExpanded}>
          <ImageSection>
            <CardImage>
              <img
                src={
                  !!user.avatar
                    ? user.avatar
                    : "/assets/images/event/placeholder.png"
                }
                alt={user.firstName}
                className="w-100"
                height={175}
                width={175}
              />
            </CardImage>
            <div className="socials-icon-container">
              {user.instagramLink && (
                <SocialIcon
                  className={"mr-2"}
                  href={user.instagramLink}
                  target={"_blank"}
                >
                  <i className="feather-instagram" />
                </SocialIcon>
              )}
              {user.linkedinLink && (
                <SocialIcon href={user.linkedinLink} target={"_blank"}>
                  <i className="feather-linkedin" />
                </SocialIcon>
              )}
              {user.tiktokLink && (
                <SocialIcon href={user.tiktokLink} target={"_blank"}>
                  <TikTokIcon src={TikTokSvg} alt="TikTok" />
                </SocialIcon>
              )}
            </div>
          </ImageSection>

          <CardBody>
            <UserContent>
              <div>
                <h4 className="fw-700 lh-3 font-xss">{user.firstName}</h4>
                <p className="profession">{user.profession}</p>
              </div>
              <Description isExpanded={isExpanded}>{user.about}</Description>
              {user.externalLink && (
                <div className="link">
                  <a
                    href={user.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.externalLinkText}
                  </a>
                </div>
              )}
              {user.internalLink && (
                <div className="link">
                  <a
                    href={user.internalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("check_my_profile")}
                  </a>
                </div>
              )}
            </UserContent>
          </CardBody>
        </ContentWrapper>
      </CardContainer>
      <ReadMoreLess onClick={onExpand} isExpanded={isExpanded}>
        {isExpanded ? t("read_less") : t("read_more")}
        <UpOutlined />
      </ReadMoreLess>
    </StyledUserCard>
  );
};

const ReadMoreLess = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  text-align: center;
  font-size: 0.7rem;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
    margin-left: 3px;

    transform: ${({ isExpanded }) =>
      isExpanded ? "rotate(0deg)" : "rotate(180deg)"};
    transition: 0.2s ease-in-out all;
  }
`;

const TikTokIcon = styled.img`
  width: 14px;
  height: 14px;
  color: ${colors.header};
`;

const SocialIcon = styled.a`
  margin-inline: 0.5rem;
  cursor: pointer;
  z-index: 10;

  i {
    font-size: 14px;
    color: ${colors.header};

    @media ${device.lg} {
      font-size: 14px;
    }
  }
`;

const StyledUserCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.7);

  padding: 1.2rem 1.2rem 2rem 1.2rem;
  border-radius: ${styles.card_border_radius};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  @media only screen and ${device.md} {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem;
  }
`;

const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 275px;

  @media only screen and ${device.md} {
    height: 300px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  transition: transform 0.3s ease-in-out;
  transform: translateY(${(props) => (props.isExpanded ? "-175px" : "0")});
  transform: translateY(${({ isExpanded }) => (isExpanded ? "-175px" : "0")});

  @media only screen and ${device.md} {
    transform: translateY(${({ isExpanded }) => (isExpanded ? "-200px" : "0")});
  }
`;

const ImageSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .socials-icon-container {
    height: 24.5px;
  }
`;

const CardImage = styled.div`
  position: relative;
  overflow: hidden;
  width: 125px;
  height: 125px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    transition: transform 0.3s ease-out;
    user-drag: none;
    -webkit-user-drag: none;
    border-radius: 50%;
  }

  @media only screen and ${device.md} {
    width: 150px;
    height: 150px;

    img {
      width: 150px;
      height: 150px;
    }
  }
`;

const CardBody = styled.div`
  padding-top: 0.5rem;
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: -0.2rem;
  }

  .profession {
    font-size: 0.6rem;
    margin-bottom: 0.4rem;
  }

  .link {
    height: 20px;
  }

  .link a {
    color: ${colors.secondary};
    font-size: 0.7rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Description = styled.p`
  font-size: 0.7rem;
  line-height: 1.5;
  overflow-wrap: anywhere;
  word-break: break-word;
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 4.5em;
  max-height: ${({ isExpanded }) => (isExpanded ? "15em" : "4.5em")};
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 6px;

  @media only screen and ${device.md} {
    font-size: 0.8rem;
    max-height: ${({ isExpanded }) => (isExpanded ? "13.5em" : "3em")};
    min-height: 3em;
    -webkit-line-clamp: 9;
  }
`;

export default UserCard;
