import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { NavItem } from "../../styles/sharedStyles";
import { productDetail } from "../../urls";

const DropdownMenu = () => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <NavItemWrapper
      className="list-inline-item pe-4 ps-4"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to="#">{t("spaces_at_oostwest")}</Link>

      {isHovered && (
        <DropdownWrapper>
          <ProductNavItem>
            <DropdownContent>
              <DropdownLink to="/product1">
                <Link to={productDetail.replace(":productId", "flexwerken")}>
                  {t("flex_working")}
                </Link>
              </DropdownLink>
              <DropdownLink to="/product2">
                <Link to={productDetail.replace(":productId", "vaste-unit")}>
                  {t("fixed_unit")}
                </Link>
              </DropdownLink>
              <DropdownLink to="/product3">
                <Link
                  to={productDetail.replace(
                    ":productId",
                    "multifunctionele-ruimte",
                  )}
                >
                  {t("multipurpose_space")}
                </Link>
              </DropdownLink>
            </DropdownContent>
          </ProductNavItem>
        </DropdownWrapper>
      )}
    </NavItemWrapper>
  );
};

const NavItemWrapper = styled(NavItem)`
  position: relative;
  display: inline-block;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 5px;
`;

const ProductNavItem = styled.div`
  min-width: 280px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 20px;
    width: 12px;
    height: 12px;
    background: white;
    transform: rotate(45deg);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const DropdownContent = styled.ul`
  padding: 8px 0;
`;

const DropdownLink = styled(NavItem)`
  display: block;
  padding: 6px 16px;
  color: ${colors.text};
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: left;

  &:hover {
    background-color: ${colors.light};
    color: ${colors.primary};
  }
`;

export default DropdownMenu;
