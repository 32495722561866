import React from "react";
import { useTranslation } from "react-i18next";
import { getReadableProfession } from "../functions/Users";
import { DashboardCard } from "../styles/dashboardStyles";
import styled from "styled-components";
import { colors } from "../utils/theme";
import { profile } from "../urls";
import LinkButton from "./LinkButton";
import TikTokSvg from "../assets/icons/tik-tok.svg";
import { device } from "../utils/breakpoints";

const ProfileDetail = ({ user, edit }) => {
  const { t } = useTranslation();
  return (
    <StyledDashboardCard>
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">{t("about")}</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
          {!!user.about
            ? user.about
            : user.firstName + " heeft nog niks ingevuld."}
        </p>
      </div>
      <div className="card-body border-top-xs d-flex">
        <i className="feather-target me-3 font-lg" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {t("profession")}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {!!user.profession
              ? getReadableProfession(user.profession)
              : t("unemployed")}
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <i className="feather-phone me-3 font-lg" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {t("phone_number")}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {!!user.phone ? user.phone : t("not_yet_set")}
          </span>
        </h4>
      </div>
      {!!user.skills && user.skills.length > 0 && (
        <div className="card-body d-flex pt-0">
          <i className="feather-heart me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">
            {t("skills")}
            {user.skills.map((skill, index) => {
              return (
                <span
                  className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                  key={index}
                >
                  {skill}
                </span>
              );
            })}
          </h4>
        </div>
      )}
      {user.externalLink && (
        <ExternalLink className="card-body d-flex pt-0">
          <i className="feather-link me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">
            {t("external_link")}
            <a
              href={user.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              className="d-block font-xssss fw-500 mt-1 lh-3"
            >
              {user.externalLinkText || user.externalLink}
            </a>
          </h4>
        </ExternalLink>
      )}
      {(user.instagramLink || user.linkedinLink || user.tiktokLink) && (
        <div className="card-body d-flex pt-0 align-items-center">
          <i className="feather-share-2 me-3 font-lg" />
          <div>
            <h4 className="fw-700 text-grey-900 font-xssss mt-0 me-3">
              {t("social_links")}
            </h4>
            <SocialIconContainer>
              {user.instagramLink && (
                <SocialIcon
                  href={user.instagramLink}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <i className="feather-instagram" />
                </SocialIcon>
              )}
              {user.linkedinLink && (
                <SocialIcon
                  href={user.linkedinLink}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <i className="feather-linkedin" />
                </SocialIcon>
              )}
              {user.tiktokLink && (
                <SocialIcon
                  href={user.tiktokLink}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <TikTokIcon src={TikTokSvg} alt="TikTok" />
                </SocialIcon>
              )}
            </SocialIconContainer>
          </div>
        </div>
      )}
      {edit && (
        <LinkButton to={profile} small type={"outlined"}>
          {t("edit")}
        </LinkButton>
      )}
    </StyledDashboardCard>
  );
};

const StyledDashboardCard = styled(DashboardCard)`
  i {
    color: ${colors.secondary};
  }
`;

const SocialIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SocialIcon = styled.a`
  margin-right: 1rem;
  cursor: pointer;
  z-index: 10;

  i {
    font-size: 14px;
    color: ${colors.header};

    @media ${device.lg} {
      font-size: 14px;
    }
  }
`;

const TikTokIcon = styled.img`
  width: 14px;
  height: 14px;
  color: ${colors.header};
`;

const ExternalLink = styled.div`
  a {
    color: ${colors.secondary};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ProfileDetail;
