import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import LinkButton from "../LinkButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BannerDescription,
  BannerTitle,
  Image,
} from "../../styles/sharedStyles";
import ReactMarkdown from "react-markdown";

const ImageAndTextBanner = ({
  src,
  alt,
  title,
  description,
  flip = false,
  styleElement = false,
  btnLink1 = "",
  btnLink2 = "",
  btnText1 = "",
  btnText2 = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="section position-relative" id="feature">
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col-lg-6 ${flip ? "order-lg-1 offset-lg-1" : ""}`}>
            <Image src={src} alt={alt} className="img-fluid" />
          </div>
          {styleElement && (
            <ButterflyStyleElement
              src={"assets/images/illustrations/illustratie-07.svg"}
            />
          )}
          <div
            className={`col-lg-5 align-self-center ${flip ? "" : "offset-lg-1"}`}
          >
            <StyledBannerTitle>
              <b>{title}</b>
            </StyledBannerTitle>
            <BannerDescription>
              <ReactMarkdown>{description}</ReactMarkdown>
            </BannerDescription>
            {!!btnLink1 && (
              <div className={"d-flex mt-2"}>
                <LinkButton to={btnLink1} small type={"primary"}>
                  {btnText1}
                </LinkButton>
                {!!btnLink2 && (
                  <LinkButton to={btnLink2} small type={"outlined"}>
                    {btnText2}
                  </LinkButton>
                )}
              </div>
              // <div className={"d-flex mt-2"}>
              //   <StyledButton onClick={() => dispatch(toggleHireSpaceModal())}>
              //     {btnText || t("rent_a_space")}
              //   </StyledButton>
              //   <LinkButton to={contact} small type={"outlined"}>
              //     {t("get_in_touch")}
              //   </LinkButton>
              // </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ButterflyStyleElement = styled.img`
  position: absolute;
  width: 180px;
  right: 15vw;
  bottom: 70vh;
  z-index: 0;

  @media ${device.maxXl} {
    bottom: -20vh;
  }

  @media ${device.maxLg} {
    display: none;
  }
`;

const StyledBannerTitle = styled(BannerTitle)`
  margin-bottom: 0.5rem;
`;

export default ImageAndTextBanner;
