import styled from "styled-components";
import { colors, styles } from "../utils/theme";
import { device } from "../utils/breakpoints";
import { Button } from "antd";

const AntdButton = styled(Button)`
  border-radius: ${styles.btn_border_radius};
  padding: 3px 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: 1rem;

  @media ${device.lg} {
    font-size: 18px;
  }
`;

const PrimaryButton = styled(AntdButton)`
  background-color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
  color: #fff;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
  margin-right: 0.6rem;
  text-transform: uppercase;
  font-size: ${({ small }) => (small ? "14px" : "initial")};

  &:hover,
  &:focus {
    background-color: #fff !important;
    color: ${colors.secondary};
  }
`;

const OutlinedButton = styled(AntdButton)`
  background-color: transparent;
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
  font-size: ${({ small }) => (small ? "14px" : "initial")};

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.secondary};
    border: 1px solid ${colors.secondary};
    color: #fff;
  }
`;

const StyledButton = ({
  type = "primary",
  small = false,
  children,
  ...props
}) => {
  switch (type) {
    case "primary":
      return (
        <PrimaryButton small {...props}>
          {children}
        </PrimaryButton>
      );
    case "outlined":
      return (
        <OutlinedButton small {...props}>
          {children}
        </OutlinedButton>
      );
    default:
      return (
        <PrimaryButton small {...props}>
          {children}
        </PrimaryButton>
      );
  }
};

export default StyledButton;
