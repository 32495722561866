import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ImageTextCards from "../Banners/ImageTextCards";
import {
  BannerDescription,
  BannerImage,
  BannerTitle,
} from "../../styles/sharedStyles";
import { community } from "../../urls";
import LinkButton from "../LinkButton";

const CommunityBanner = ({
  title,
  description,
  images,
  displayButton = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="section position-relative">
      <div className="container text-center">
        <BannerImage
          src="/assets/images/illustrations/illustratie-13.svg"
          alt="Oostwest"
          className="img-fluid"
        />
        <StyledBannerTitle>{title}</StyledBannerTitle>
        <StyledBannerDescription>{description}</StyledBannerDescription>
        <ImageTextCards className={"mt-3 mb-1"} images={images} />
        {!!displayButton && (
          <LinkButton to={community} small type={"outlined"} className={"mt-2"}>
            {t("check_community")}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

const StyledBannerDescription = styled(BannerDescription)`
  margin-bottom: 2rem;
`;

const StyledBannerTitle = styled(BannerTitle)`
  font-weight: 800;
  margin-top: 0;
`;

export default CommunityBanner;
