import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import data from "../../data/hire-space-spaces.json";
import { useDispatch, useSelector } from "react-redux";
import { toggleHireSpaceModal } from "../../redux/actions/modalActions";
import StyledButton from "../StyledButton";
import Check from "../Check";
import { sendHireSpaceConfirmationEmail } from "../../functions/Api";
import {
  SPACE_ID_FLEX_WORKSPACE,
  SPACE_ID_MEETING_ROOM,
  SPACE_ID_STUDIO,
} from "../../constants/SpacesConstants";
import locale from "antd/es/date-picker/locale/nl_NL";
import dayjs from "dayjs";
import { getDisableDatesForMeetingRoom } from "../../functions/Datepicker";
import * as confirmedAnimation from "../../assets/lottie/checkAnimation.json";
import Lottie from "react-lottie-player";
import {
  FIRESTORE_HIRE_REQUESTS_TABLE,
  FIRESTORE_HIRE_SPACE_EMAILS_TABLE,
} from "../../constants/FirebaseConstants";
import { useFirestore } from "react-redux-firebase";
import { HIRE_REQUEST_STATUS_OPEN } from "../../constants/HireRequestConstants";
import useIsMobile from "../Hooks/useIsMobile";

const HireSpaceModal = ({ preselectedSpaceId }) => {
  const { t, i18n } = useTranslation();
  const modalData = useSelector((state) => state.modalData);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const lang = i18n.language;
  const modalRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [chosenSpace, setChosenSpace] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!!modalData && modalData.hireSpace) {
      setModalVisible(true);
      if (preselectedSpaceId) {
        const space = data[lang].find(
          (space) => space.id === preselectedSpaceId,
        );
        if (space) {
          setChosenSpace(space);
          setStepCount(1); // Skip first step
        }
      }
    } else {
      setModalVisible(false);
    }

    if (chosenSpace && !preselectedSpaceId) {
      handleNext(chosenSpace);
    }
  }, [modalData, preselectedSpaceId, lang, chosenSpace]);

  const handleNext = () => {
    if (stepCount === 0) {
      if (!!chosenSpace) {
        setStepCount(stepCount + 1);
        scrollTop(modalRef);
      } else message.warning(t("form.choose_space"));
    }
    if (stepCount === 1) {
      form.validateFields().then((values) => {
        setIsLoading(true);
        setName(values.firstName);
        setEmail(values.email);
        values.chosenSpace = chosenSpace.id;
        if (!!values.reservationDate)
          values.reservationDate = dayjs(values.reservationDate).format(
            "DD-MM-YYYY",
          );
        else delete values.reservationDate;
        values.createdAt = dayjs().unix();
        values.status = HIRE_REQUEST_STATUS_OPEN;
        values.phone = values.phone || null;
        values.timePeriod = values.timePeriod || null;
        // Send e-mail
        sendHireSpaceConfirmationEmail(values)
          .then(() => {
            setStepCount(stepCount + 1);
            scrollTop(modalRef);
            setIsLoading(false);
            firestore.collection(FIRESTORE_HIRE_SPACE_EMAILS_TABLE).add(values);
            firestore.collection(FIRESTORE_HIRE_REQUESTS_TABLE).add(values);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      });
    }
  };

  const handlePrevious = () => {
    if (stepCount === 0 || (stepCount === 1 && preselectedSpaceId)) {
      closeModal();
    } else {
      setStepCount(stepCount - 1);
      scrollTop(modalRef);
    }
  };

  const scrollTop = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const closeModal = () => {
    dispatch(toggleHireSpaceModal());
    setTimeout(() => {
      setChosenSpace(null);
      setStepCount(0);
      form.resetFields();
    }, 500);
  };

  const Choices = ({ choices }) => {
    const isMobile = useIsMobile();
    return (
      <ProductsWrapper itemAmount={choices.length} gutter={16}>
        {choices.map((item, index) => {
          return (
            <Col xs={24} md={12} lg={8} key={index}>
              <ProductContainer
                activeItem={!!chosenSpace && chosenSpace.id === item.id}
                onClick={() => {
                  setChosenSpace(item);
                }}
              >
                {!!item.image && !isMobile && (
                  <ProductImage src={item.image} alt={item.image_alt} />
                )}
                <ProductContent>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                  {item.bullets.map((bullet, i) => {
                    return (
                      <Bullet key={i}>
                        <CheckWrapper>
                          <Check color={colors.header} />
                        </CheckWrapper>
                        <span>{bullet}</span>
                      </Bullet>
                    );
                  })}
                </ProductContent>
              </ProductContainer>
            </Col>
          );
        })}
      </ProductsWrapper>
    );
  };

  return (
    <StyledModal
      title=""
      visible={modalVisible}
      centered
      onOk={() => closeModal(false)}
      onCancel={() => closeModal(false)}
      footer={null}
      width={1000}
    >
      {/* div used to scroll to top of modal after every step */}
      <div ref={modalRef} />
      {/* Product chooser */}
      {stepCount === 0 && (
        <div>
          <Title>{t("hire_space_options_title")}</Title>
          <Description>{t("hire_space_options_description")}</Description>
          <Choices choices={data[lang]} />
        </div>
      )}
      {/*Contact info form*/}
      {stepCount === 1 && !!chosenSpace && (
        <div>
          <SubTitle>
            {chosenSpace.id === SPACE_ID_STUDIO
              ? t("fixed_unit")
              : chosenSpace.id === SPACE_ID_FLEX_WORKSPACE
                ? t("flex_working")
                : t("multipurpose_space")}
          </SubTitle>
          <Title>{t("hire_space_form_title")}</Title>
          <Description>{t("hire_space_form_description")}</Description>
          {chosenSpace.id === SPACE_ID_STUDIO && (
            <Description>{t("hire_space_form_caution")}</Description>
          )}
          {!!chosenSpace && (
            <StyledForm form={form} layout={"vertical"}>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="firstName"
                    label={t("first_name")}
                    rules={[
                      {
                        required: true,
                        message: t("form.enter_first_name"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="lastName"
                    label={t("last_name")}
                    rules={[
                      {
                        required: true,
                        message: t("form.enter_last_name"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="email"
                    label={t("email")}
                    rules={[
                      {
                        required: true,
                        message: t("form.enter_email"),
                      },
                      {
                        type: "email",
                        message: t("form.enter_valid_email"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="phone" label={t("phone_number")}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {chosenSpace.id === SPACE_ID_MEETING_ROOM ? (
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="reservationDate"
                      label={t("reservation_date_meeting_room_placeholder")}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY"}
                        placeholder={t("choose_date")}
                        showToday={false}
                        inputReadOnly={true}
                        disabledDate={(current) => {
                          return getDisableDatesForMeetingRoom(current);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name="timePeriod" label={t("for_how_long")}>
                      <Select
                        placeholder={t("placeholders.choose_time_period")}
                      >
                        <Select.Option value="morning">
                          {t("morning")}
                        </Select.Option>
                        <Select.Option value="afternoon">
                          {t("afternoon")}
                        </Select.Option>
                        <Select.Option value="whole_day">
                          {t("whole_day")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={24}>
                    <Form.Item
                      name="occupation"
                      label={t("occupation_form_question")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_occupation"),
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder={t("occupation_form_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </StyledForm>
          )}
        </div>
      )}
      {/* Confirmation Screen */}
      {stepCount === 2 && (
        <div className={"p-lg-5"}>
          <LottieWrapper>
            <Lottie
              animationData={confirmedAnimation}
              play
              // style={{ height: 200, width: 200 }}
            />
          </LottieWrapper>
          <Title>{t("hire_space_confirm_title", { name: name })}</Title>
          <SubTitle>
            {t("hire_space_confirm_description", { email: email })}
          </SubTitle>
        </div>
      )}
      {/* Go back and next buttons OR continue shopping & go to cart buttons */}
      {stepCount < 2 ? (
        <ButtonContainer>
          <StepBackButton onClick={handlePrevious}>
            {t("go_back")}
          </StepBackButton>
          <StyledButton small onClick={handleNext} loading={isLoading}>
            {t("next")}
          </StyledButton>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <StyledButton small onClick={closeModal}>
            {t("finish")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledModal>
  );
};

const LottieWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  svg {
    max-width: 130px;
    @media ${device.lg} {
      max-width: 200px;
    }
  }
`;

const StyledForm = styled(Form)`
  input,
  textarea {
    background-color: transparent !important;
    border: 1px solid #b0b0b0;
  }

  .ant-picker {
    background-color: transparent !important;

    input {
      border: none;
    }
  }

  @media ${device.lg} {
    padding: 2rem 3rem;
  }
`;

const CheckWrapper = styled.div`
  min-width: 24px;
  margin-right: 0.75rem;
`;

const Bullet = styled.div`
  text-align: start;
  display: flex;
  margin-bottom: 0.4rem;
  align-items: center;

  .anticon {
    font-size: 24px;
    color: green;
    margin-right: 0.4rem;
  }

  span {
    line-height: 18px;
  }
`;

const ProductImage = styled.img`
  max-width: 100%;
  min-height: 172px;
  border-radius: 10px;
`;

const ProductsWrapper = styled(Row)`
  display: flex;
  padding: 1rem 0 1rem 0;
  justify-content: center;

  @media ${device.lg} {
    justify-content: ${({ itemAmount }) =>
      itemAmount < 3 ? "center" : "space-between"};
  }
`;

const ProductContent = styled.div`
  padding: 1rem;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.3s all ease;
  border: 1px solid lightgrey;
  margin-bottom: 1rem;
  ${({ activeItem }) =>
    activeItem &&
    `
      box-shadow: 0px 5px 24px rgb(0 0 0 / 30%);
      background-color: rgb(241,232,216, 0.5)
    `};

  &:hover,
  &.active,
  &.overview {
    box-shadow: 0px 2px 24px rgb(0 0 0 / 15%);
    cursor: pointer;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${colors.header};
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  @media ${device.maxSm} {
    margin-bottom: 2rem;
  }
`;

const StepBackButton = styled.button`
  background: transparent;
  align-self: center;
  border: none;
  color: ${colors.primary};
  font-weight: 500;
  margin-right: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 0;
`;

const SubTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.p`
  font-size: 15px;
  text-align: center;
  white-space: pre-line;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.header};
  text-align: center;
  margin: 1rem 0;

  @media ${device.lg} {
    font-size: 30px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: ${colors.light};
  }
`;

export default HireSpaceModal;
