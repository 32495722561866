import React, { Fragment, useEffect, useState } from "react";
import Logo from "../components/Logo";
import {
  dashboardHome,
  pinCodeVerificationLink,
  requestPinCodeLink,
} from "../urls";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, notification } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [showPinInput, setShowPinInput] = useState(false);
  const [uid, setUid] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        notification.success({
          message: t(
            !!profile && !!profile.fullName
              ? "notifications.welcome_back_name"
              : "notifications.welcome_back",
            {
              name:
                !!profile && !!profile.fullName
                  ? profile.fullName.split(" ")[0]
                  : null,
            },
          ),
        });
        navigate(dashboardHome);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleRequestPin = async () => {
    form.validateFields(["email"]).then(async (values) => {
      setIsLoading(true);
      const emailValue = values.email.toLowerCase().trim();
      const response = await axios
        .post(requestPinCodeLink, {
          email: emailValue,
        })
        .catch((err) => {
          setIsLoading(false);
          form.resetFields();
          notification.error({
            message: t("errors.login_no_user_title"),
            description: t("errors.login_no_user_description"),
            duration: 0,
          });
        });

      if (!!response && response.status === 200) {
        setUid(response.data.uid);
        setShowPinInput(true);
        notification.success({
          message: t("notifications.user_found"),
          description: t("notifications.verification_email_sent"),
        });
      }
      setIsLoading(false);
    });
  };

  const handlePinVerification = async () => {
    form.validateFields(["pin"]).then(async (values) => {
      setIsLoading(true);
      try {
        const response = await axios.post(pinCodeVerificationLink, {
          uid: uid,
          code: values.pin,
        });

        if (response.status === 200) {
          await signInWithCustomToken(auth, response.data.authToken);
          navigate(dashboardHome);
        }
      } catch (error) {
        notification.error({
          message: t("errors.invalid_pin_title"),
          description: t("errors.invalid_pin_description"),
        });
        setIsLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100 justify-content-between">
            <Logo header />
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{
              backgroundImage: 'url("/assets/images/about/bram-speak.webp")',
            }}
          />
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size">
                  {t("login_form_title")}
                </h2>
                <p className={"mb-3"}>{t("login_form_description")}</p>
                <Form form={form} layout={"vertical"}>
                  <Form.Item
                    name="email"
                    label={t("email")}
                    rules={[
                      {
                        required: true,
                        message: t("form.enter_email"),
                      },
                    ]}
                  >
                    <Input
                      disabled={showPinInput}
                      onPressEnter={() => !showPinInput && handleRequestPin()}
                    />
                  </Form.Item>

                  {showPinInput && (
                    <Form.Item
                      name="pin"
                      label={t("pin")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_pin"),
                        },
                        {
                          len: 6,
                          message: t("form.pin_length"),
                        },
                      ]}
                    >
                      <Input.OTP
                        size={"large"}
                        type={"number"}
                        inputMode={"numeric"}
                      />
                    </Form.Item>
                  )}

                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <Button
                        type="primary"
                        block
                        loading={isLoading}
                        onClick={
                          showPinInput
                            ? handlePinVerification
                            : handleRequestPin
                        }
                      >
                        {showPinInput ? t("verify_pin") : t("request_pin")}
                      </Button>
                    </div>
                    {showPinInput && (
                      <Button
                        type="link"
                        disabled={isLoading}
                        onClick={() => {
                          setShowPinInput(false);
                          form.resetFields(["pin"]);
                        }}
                      >
                        {t("use_different_email")}
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
