import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import {
  DashboardSection,
  DashboardWrapper,
  StyleElementContainer,
} from "../styles/dashboardStyles";
import styled from "styled-components";
import StyleElement from "../components/StyleElement";
import { device } from "../utils/breakpoints";
import HomeLayout from "../layouts/HomeLayout";
import { HeaderSubtitle, HeaderTitle } from "../styles/sharedStyles";
import UserCard from "../components/UserCard";
import { Spin } from "antd";
import EnterEmailBanner from "../components/Banners/EnterEmailBanner";
import CommunityBanner from "../components/Home/CommunityBanner";
import communityImages from "../data/community-cards.json"; // Import Spin from antd for loading state

const Community = () => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.firestoreReducer.ordered.users);
  const isLoading = useSelector(
    (state) => state.firestoreReducer.status.requesting.users,
  );

  const [sortedUsers, setSortedUsers] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);

  useEffect(() => {
    // Only process users if they exist and searchResult is empty
    if (users && users.length > 0 && searchResult.length === 0) {
      const nextSortedUsers = sortBy(users, [
        (item) => {
          return item.firstName + " " + item.lastName;
        },
      ]);
      setSortedUsers(nextSortedUsers);
    } else if (searchResult.length > 0) {
      const nextSortedUsers = searchResult.map((item) => item.item);
      setSortedUsers(nextSortedUsers);
    }
  }, [users, searchResult]);

  const handleCardExpand = (eventId) => {
    setExpandedCardId(expandedCardId === eventId ? null : eventId);
  };

  // Show loading state while data is being fetched
  if (isLoading) {
    return (
      <HomeLayout>
        <DashboardWrapper>
          <DashboardSection>
            <LoadingWrapper>
              <Spin size="large" />
            </LoadingWrapper>
          </DashboardSection>
        </DashboardWrapper>
      </HomeLayout>
    );
  }

  // Show message if no users are found
  if (!users || users.length === 0) {
    return (
      <HomeLayout>
        <DashboardWrapper>
          <DashboardSection>
            <HeaderWrapper>
              <HeaderTitle>{t("the_oostwest_community")}</HeaderTitle>
              <HeaderSubtitle>{t("no_members_found")}</HeaderSubtitle>
            </HeaderWrapper>
          </DashboardSection>
        </DashboardWrapper>
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      <DashboardWrapper>
        <DashboardSection>
          <HeaderWrapper>
            <HeaderTitle>{t("the_oostwest_community")}</HeaderTitle>
            <HeaderSubtitle>{t("our_members")}</HeaderSubtitle>
          </HeaderWrapper>
          <UsersGrid>
            {sortedUsers &&
              sortedUsers
                .filter((user) => user && user.public === true)
                .map((user, i) => (
                  <UserCard
                    key={i}
                    user={user}
                    isExpanded={expandedCardId === i}
                    onExpand={() => handleCardExpand(i)}
                  />
                ))}
          </UsersGrid>
        </DashboardSection>
      </DashboardWrapper>
      <StyleElementContainer>
        <StyleElement
          src={"/assets/images/illustrations/illustratie-14.svg"}
          alt={""}
          bottom={-20}
          right={-13}
        />
      </StyleElementContainer>
      <EnterEmailBanner />
      <CommunityBanner
        title={t("home_community_title")}
        description={t("home_community_description")}
        images={communityImages}
      />
    </HomeLayout>
  );
};

const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

const UsersGrid = styled.div`
  z-index: 1;
  display: grid;
  gap: 1rem;
  margin-bottom: 4rem;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and ${device.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and ${device.md} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and ${device.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default Community;
