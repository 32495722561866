import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../utils/theme";
import CtaBanner from "../components/Banners/CtaBanner";
import { device } from "../utils/breakpoints";
import StyleElement from "../components/StyleElement";
import ImageAndTextBanner from "../components/Banners/ImageAndTextBanner";
import FullWidthImageAndTextBanner from "../components/Banners/FullWidthImageAndTextBanner";
import NewsBanner from "../components/Banners/NewsBanner";
import newsBannerData from "../data/news-articles.json";
import HomeLayout from "../layouts/HomeLayout";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import AboutSection from "../components/Home/AboutSection";
import AboutData from "../data/about-grid-mosaic.json";
import CommunityBanner from "../components/Home/CommunityBanner";
import communityImages from "../data/community-cards.json";
import { fixedUnit, publicEvents } from "../urls";

const About = () => {
  const { t } = useTranslation();
  return (
    <HomeLayout>
      <div className={"position-relative"}>
        <ButterflyStyleElement
          src={"assets/images/illustrations/illustratie-05.svg"}
          alt={""}
        />
      </div>
      <HeaderWrapper className={"container"}>
        <div className="row h-100 align-items-center">
          <HeaderCol
            className="col-lg-5 col-md-6 col-sm-12 mt-2 mt-lg-0"
            order={2}
            orderMd={1}
          >
            <HeaderTitle>{t("about_header_title")}</HeaderTitle>
            <HeaderDescription>{t("about_header_subtitle")}</HeaderDescription>
          </HeaderCol>
          <HeaderCol
            className="col-lg-7 col-md-6 col-sm-12"
            order={1}
            orderMd={2}
          >
            <HeaderImage
              src={"assets/images/impression/1600x1146-talks-1.webp"}
              alt={""}
            />
          </HeaderCol>
        </div>
      </HeaderWrapper>
      <AboutSection
        data={AboutData}
        title={t("about_section_1_title")}
        description={t("about_section_1_description")}
        cta={t("interested_in_events")}
        btnText={t("check_events")}
        btnLink={publicEvents}
      />
      <CommunityBanner
        title={t("home_community_title")}
        description={t("home_community_description")}
        images={communityImages}
        displayButton
      />{" "}
      <ImageAndTextBanner
        src={"assets/images/impression/ferdinand-on-workspace-1-1.webp"}
        alt={""}
        title={t("about_section_2_title")}
        description={t("about_section_2_description")}
        flip
      />
      <ImageAndTextBanner
        src={"assets/images/impression/ruimte-ow-3.webp"}
        alt={""}
        title={t("about_section_3_title")}
        description={t("about_section_3_description")}
        btnLink1={fixedUnit}
        btnText1={t("see_our_studios")}
      />
      <div className={"position-relative"}>
        <FlowersStyleElement
          src={"assets/images/illustrations/illustratie-14.svg"}
        />
      </div>
      <div className={"position-relative"}>
        <ToolsStyleElement
          src={"/assets/images/illustrations/illustratie-04.svg"}
          alt={""}
        />
      </div>
      <FullWidthImageAndTextBanner
        src={"assets/images/about/about-section-founding.webp"}
        alt={""}
        title={t("about_section_4_title")}
        description={t("about_section_4_description")}
      />
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-16.svg"}
          alt={""}
          bottom={-40}
          right={-10}
        />
      </div>
      <NewsBanner data={newsBannerData} />
      <CtaBanner light />
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-09.svg"}
          alt={""}
          bottom={-25}
          left
        />
      </div>
      <HireSpaceModal />
    </HomeLayout>
  );
};

const FlowersStyleElement = styled.img`
  position: absolute;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.2;
  width: 300px;
  bottom: -20vh;
  left: -10vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -25vh;
    left: -20vh;
  }
`;

const ToolsStyleElement = styled.img`
  position: absolute;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.05;
  width: 300px;
  bottom: 30vh;
  right: -15vh;

  @media ${device.lg} {
    width: 700px;
    bottom: 20vh;
    right: -30vh;
  }
`;

const HeaderDescription = styled.p`
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;

  @media ${device.lg} {
    font-size: 24px;
    line-height: 30px;
  }
`;

const ButterflyStyleElement = styled.img`
  position: absolute;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.2;
  width: 300px;
  bottom: -92vh;
  left: -10vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -100vh;
    left: -20vh;
  }
`;

const HeaderImage = styled.img`
  width: fill-available;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

const HeaderSubtitle = styled.h4`
  font-size: 20px;
  font-weight: 300;

  @media ${device.lg} {
    font-size: 24px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 0;
  color: ${colors.header};

  @media ${device.lg} {
    font-size: 38px;
  }
`;

const HeaderCol = styled.div`
  height: fit-content;
  order: ${({ order }) => order};

  @media ${device.md} {
    order: ${({ orderMd }) => orderMd};
  }
`;

const HeaderWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 8rem;
  height: 75vh;
  position: relative;
  @media ${device.maxSm} {
    background-image: none !important;
  }
`;

export default About;
