import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import HomeLayout from "../layouts/HomeLayout";
import { device } from "../utils/breakpoints";
import { colors } from "../utils/theme";
import StyledButton from "../components/StyledButton";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import { useDispatch } from "react-redux";
import StyleElement from "../components/StyleElement";
import LinkButton from "../components/LinkButton";
import { contact } from "../urls";
import CtaBanner from "../components/Banners/CtaBanner";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import CommunityBanner from "../components/Home/CommunityBanner";
import EnterEmailBanner from "../components/Banners/EnterEmailBanner";
import { Col, Row } from "antd";
import { Image, StyledText } from "../styles/sharedStyles";

const ProductDetail = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [activeImage, setActiveImage] = useState(null);

  const productToSpaceId = {
    flexwerken: "FLEX_SPACE", // Replace with actual IDs from your data
    "vaste-unit": "STUDIO_SPACE",
    "multifunctionele-ruimte": "MEETING_SPACE",
  };

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const productData = await import(`../data/products/${productId}.json`);
        setProduct(productData.default);
        setActiveImage(productData.default.images[0]);
      } catch (error) {
        console.error("Error loading product:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    loadProduct();
  }, [productId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Navigate to="/" replace />;
  }

  if (!product) {
    return <Navigate to="/" replace />;
  }

  return (
    <HomeLayout>
      <div className={"position-relative"}>
        <StyleElement
          src={"/assets/images/illustrations/illustratie-18.svg"}
          alt={""}
          right={-18}
          bottom={-110}
        />
      </div>
      <HeaderWrapper>
        <div className="section position-relative" id="feature">
          <div className="row justify-content-center">
            <div className={`col-lg-6`}>
              <Image src={activeImage} className="img-fluid" />
              <Row>
                {product.images.slice(0, 4).map((image, index) => (
                  <Col
                    key={index}
                    xs={6}
                    md={6}
                    style={{ paddingTop: 10, paddingRight: 10 }}
                  >
                    <Image
                      src={image}
                      className="img-fluid"
                      onClick={() => setActiveImage(image)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div className={`col-lg-5 align-self-center`}>
              <HeaderTitle>{product.title}</HeaderTitle>
              <StyledText>{product.subtitle}</StyledText>
              <Price>
                <p className="price">{product.price}</p>
                <p className="price-note">{product.price_note}</p>
              </Price>

              <div className={"d-flex mt-2"}>
                <StyledButton
                  type="primary"
                  onClick={() => dispatch(toggleHireSpaceModal())}
                >
                  {product.primary_btn_text}
                </StyledButton>
                <LinkButton to={contact} small type={"outlined"}>
                  {t("get_in_touch")}
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <CommunityBanner
        title={product.usp_title}
        description={product.usp_description}
        images={product.usp_cards}
        displayButton
      />
      <EnterEmailBanner />

      {/*<FullWidthImageBanner*/}
      {/*  backgroundImages={product.bg_images}*/}
      {/*  defaultBgImage={product.default_bg_image}*/}
      {/*/>*/}

      <CtaBanner light />
      <HireSpaceModal preselectedSpaceId={productToSpaceId[productId]} />
    </HomeLayout>
  );
};

const Price = styled.div`
  margin-bottom: 26px;

  p {
    margin: 0;

    &.price {
      margin-top: 20px;
      font-size: 22px;
      color: ${colors.secondary};
      font-weight: 600;
    }

    &.price-note {
      margin-top: 2px;
      font-size: 12px;
    }
  }
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
`;

const HeaderTitle = styled.h1`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${colors.header};
  @media ${device.lg} {
    font-size: 38px;
    margin-top: 0;
  }
`;

export default ProductDetail;
